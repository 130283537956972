import { useEffect, useRef } from 'react';
// Components
import VideoControlButton from 'ui/VideoPlayer/components/VideoControlButton/VideoControlButton';

import styles from './VolumeSeek.module.scss';

type VolumeSeekProps = {
  muted: boolean;
  volume: number;
  onSeek: (time: number) => void;
  onMute: () => void;
  onUnMute: () => void;
};

const VolumeSeek = ({
  muted,
  volume,
  onSeek,
  onMute,
  onUnMute,
}: VolumeSeekProps) => {
  const prevVolumeRef = useRef<number>(volume);

  const seekInputRef = useRef<HTMLInputElement | null>(null);

  const isMuted = muted || !volume;

  const setSeekProperty = (value: number | string) => {
    seekInputRef.current?.style.setProperty(
      '--seek-before-width',
      `${+value * 100}%`
    );
  };

  useEffect(() => {
    setSeekProperty(volume);

    if (seekInputRef.current) {
      seekInputRef.current.value = `${volume}`;
    }
  }, [volume]);

  const handleSeekInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const seekInputValue = Number(e.target.value);

    setSeekProperty(seekInputValue);
    onSeek(seekInputValue);

    if (muted && seekInputValue) {
      onUnMute();
    }
  };

  const handleButtonClick = () => {
    if (muted) {
      const restoreVolume = prevVolumeRef.current ? prevVolumeRef.current : 0.5;
      onUnMute();
      setSeekProperty(restoreVolume);
      onSeek(restoreVolume);
    } else if (!muted && !volume) {
      setSeekProperty(0.5);
      onSeek(0.5);
    } else if (!muted && volume) {
      onMute();
      prevVolumeRef.current = volume;
      setSeekProperty(0);
      onSeek(0);
    }
  };

  return (
    <div className={styles.root}>
      <VideoControlButton
        icon={isMuted ? 'volume-muted' : 'volume'}
        title={isMuted ? 'Unmute' : 'Change volume'}
        onClick={handleButtonClick}
      />

      <div className={styles.seekWrapper}>
        <input
          tabIndex={0}
          aria-label="volume seek"
          type="range"
          min="0"
          step="0.1"
          max="1"
          onChange={handleSeekInputChange}
          className={styles.seek}
          ref={seekInputRef}
        />
      </div>
    </div>
  );
};

export default VolumeSeek;
