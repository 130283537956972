import cn from 'classnames';
// Icons
import VolumeMuted from '../../../../public/images/video-player/volume-muted.svg';
import Volume from '../../../../public/images/video-player/volume.svg';
import Fullscreen from '../../../../public/images/video-player/fullscreen.svg';
import Pause from '../../../../public/images/video-player/pause.svg';
import Play from '../../../../public/images/video-player/play.svg';
import Refresh from '../../../../public/images/video-player/refresh.svg';
import Settings from '../../../../public/images/video-player/settings.svg';
import Camera from '../../../../public/images/video-player/camera.svg';
import DisabledCamera from '../../../../public/images/video-player/disabled-camera.svg';
import Gear from '../../../../public/images/video-player/gear.svg';
import Microphone from '../../../../public/images/icons/microphone.svg';
import MicrophoneMuted from '../../../../public/images/icons/microphone-muted.svg';
import PlayNext from '../../../../public/images/video-player/play-next.svg';

import styles from './VideoControlButton.module.scss';

const ICON_NAMES = {
  'volume-muted': VolumeMuted,
  volume: Volume,
  fullscreen: Fullscreen,
  pause: Pause,
  play: Play,
  refresh: Refresh,
  settings: Settings,
  camera: Camera,
  'disabled-camera': DisabledCamera,
  gear: Gear,
  mic: Microphone,
  'mic-muted': MicrophoneMuted,
  'play-next': PlayNext,
};

export type IconNames = keyof typeof ICON_NAMES;

type VideoControlButtonProps = {
  icon: IconNames;
} & React.ComponentPropsWithoutRef<'button'>;

const VideoControlButton = ({
  icon,
  className,
  ...rest
}: VideoControlButtonProps) => {
  const SvgIcon = ICON_NAMES[icon] as 'svg';

  return (
    <button className={cn(styles.root, className)} {...rest}>
      <SvgIcon
        aria-hidden="true"
        role="presentation"
        focusable="false"
        data-icon-name={icon}
      />
    </button>
  );
};

export default VideoControlButton;
