import { useState, useRef } from 'react';
import { useClickAway } from 'react-use';
import cn from 'classnames';
// Components
import VideoControlButton from '../VideoControlButton/VideoControlButton';
// Styles
import styles from './QualityOptions.module.scss';

type Option = { label: string; value: number };

type QualityOptionsProps = {
  options: Option[];
  onChange: (quality: number) => void;
};

const QualityOptions = ({ options, onChange }: QualityOptionsProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [val, setVal] = useState<string>(options[0].label);

  useClickAway(ref, () => {
    setOpen(false);
  });

  const handleMenuToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleQualityChange = (quality: Option) => {
    setVal(quality.label);
    onChange(quality.value);
  };

  return (
    <div
      className={cn(styles.root, {
        [styles.open]: open,
      })}
      role="button"
      onClick={handleMenuToggle}
      onKeyPress={handleMenuToggle}
      ref={ref}
      tabIndex={0}
      aria-label="Change quality"
    >
      <VideoControlButton icon="gear" title={val} role="presentation" />

      {open && (
        <ul className={styles.options} role="menu">
          {options.map((item) => {
            const handleItemClick = () => {
              handleQualityChange(item);
            };

            return (
              <li
                tabIndex={0}
                key={item.label}
                className={styles.optionItem}
                role="menuitem"
                onClick={handleItemClick}
                onKeyPress={handleItemClick}
              >
                {item.label}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default QualityOptions;
