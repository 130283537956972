// Components
import VideoControlButton from 'ui/VideoPlayer/components/VideoControlButton/VideoControlButton';

import styles from './MicControl.module.scss';

type MicControlProps = {
  muted: boolean;
  onMute: () => void;
  onUnMute: () => void;
};

const MicControl = ({ muted, onMute, onUnMute }: MicControlProps) => {
  const isMuted = muted;

  const handleButtonClick = () => {
    if (muted) {
      onUnMute();
    } else if (!muted) {
      onMute();
    }
  };

  return (
    <div className={styles.root}>
      <VideoControlButton
        icon={isMuted ? 'mic-muted' : 'mic'}
        title={isMuted ? 'Unmute' : 'Mute'}
        onClick={handleButtonClick}
      />
    </div>
  );
};

export default MicControl;
